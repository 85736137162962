<template>
  <div class="layout-footer">
    <div class="p-grid">
      <div class="p-col-4">
        <span class="footer-text">© 2021</span>
      </div>
      <div class="p-col-4 p-offset-4 p-text-right">
        <span class="footer-text">v0.10.1</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppFooter"
}
</script>

<style scoped>

</style>
