<template src="./dashboard.html"></template>
<style src="./dashboard.css" scoped></style>

<script>
  import moment from 'moment';

  export default {
    name: 'dashboard',
    data() {
      return {
        recentJournalHeight: '400px',
        loading: false,
        selectedJournal: null
      }
    },

    created() {
      this.$store.commit('changeTitle', 'Dashboard | RSN');
      this.$store.dispatch('fetchDashboard');
    },

    computed: {
      dashboard() {
        return this.$store.state.dashboard;
      },

      entries() {
        return `Journal Entries (${this.dashboard.journalEntryCount})`;
      },

      recentJournalStyle() {
        return `width: 100%; height: ${this.recentJournalHeight}`;
      },

      expandedRows() {
        if (!this.$store.state.dashboard || !this.$store.state.dashboard.recentJournalEntries) {
          return null;
        }

        return this.$store.state.dashboard.recentJournalEntries.filter(p => p.id);
      }
    },

    methods: {
      loadJournal() {
        return this.$router.push({
          name: 'JournalOverview'
        });
      },

      loadEntry(event) {
        return this.$router.push({
          name: 'JournalView',
          params: {
            journalID: event.data.id
          }
        });
      },

      createEntry() {
        return this.$router.push({
          name: 'JournalView',
          params: {
            journalID: 'new'
          }
        });
      }
    },

    filters: {
      time(date) {
        return moment(date).format('YYYY-MM-DD h:mm a')
      }
    }
  }
</script>
