<template src="./payee-view.html"></template>
<style src="./payee-view.css" scoped></style>

<script>
  import axios from 'axios';

  export default {
    name: 'payee-view',
    data() {
      return {
        loading: false,
        editing: false,
        inplaceFields: {},
        payees: [],
        payeeDialog: false,
        payee: {},
        selectedPayees: null,
        deletePayeesDialog: false,
        filters: {},
        submitted: false
      }
    },

    created() {
      this.$store.commit('changeTitle', 'Payees | RSN');
      this.fetchData();
    },

    methods: {
      fetchData() {
        axios.get('/payee/')
        .then(response => {
          this.payees = response.data.results;
        })
        .catch(err => {
          console.error('error loading payees:', err);
        });
      },

      openNew() {
        const today = new Date();

        this.payee = {};
        this.submitted = false;
        this.payeeDialog = true;
      },

      hideDialog() {
        this.payeeDialog = false;
        this.submitted = false;
      },

      savePayee() {
        if (!this.payee.name.trim()) {
          this.submitted = true;
          return;
        }

        if (this.payee.id) {
          // TODO: Update existing record
          this.$set(this.payees, this.findIndexById(this.payee.id), this.payee);
          this.$toast.add({severity:'success', summary: 'Successful', detail: 'Product Updated', life: 3000});
          this.submitted = true;
          this.payeeDialog = false;
          this.payee = {};
          return;
        }

        this.$store.dispatch('createPayee', this.payee)
        .then(payee => {
          this.payees.push(payee);
          this.$toast.add({severity:'success', summary: 'Successful', detail: 'Account Created', life: 2000});

          this.submitted = true;
          this.payeeDialog = false;
          this.payee = {};
        })
        .catch(err => {
          this.$toast.add({severity: 'error', summary: 'Failure', detail: 'Payee Creation Error', life: 2000});
        });
      },

      findIndexById(id) {
        let index = -1;
        for (let i = 0; i < this.payees.length; i++) {
          if (this.payees[i].id === id) {
            index = i;
            break;
          }
        }

        return index;
      },

      exportCSV() {
        this.$refs.dt.exportCSV();
      },

      confirmDeleteSelected() {
        this.deletePayeesDialog = true;
      },

      deleteSelectedPayees() {
        this.payees = this.payees.filter(val => !this.selectedPayees.includes(val));
        this.deletePayeesDialog = false;
        this.selectedPayees = null;
        this.$toast.add({severity:'success', summary: 'Successful', detail: 'Products Deleted', life: 3000});
      },

      isPositiveInteger(val) {
        let str = String(val);
        str = str.trim();
        if (!str) {
          return false;
        }
        str = str.replace(/^0+/, "") || "0";
        var n = Math.floor(Number(str));
        return n !== Infinity && String(n) === str && n >= 0;
      },

      // Cell editing

      startEdit(field, id, data) {
        this.inplaceFields[`${field}.${id}`] = data;

        setTimeout(() => {
          if (typeof data === 'string') {
            this.$refs[`${field}.${id}`].$children[0].$el.focus();
          }
          else {
            this.$refs[`${field}.${id}`].$children[0].$children[0].$el.focus();
          }
        });
      },

      cancelEdit(field, payee) {
        payee[field] = this.inplaceFields[`${field}.${payee.id}`];
        delete this.inplaceFields[`${field}.${payee.id}`];
      },

      saveEdit(field, payee) {
        if (payee[field] === this.inplaceFields[`${field}.${payee.id}`]) {
          return this.$refs[`${field}.${payee.id}`].close();
        }

        setTimeout(() => {
          if (!(`${field}.${payee.id}` in this.inplaceFields)) {
            return;
          }

          this.$store.dispatch('updatePayee', payee)
          .then(response => {
            this.inplaceFields[`${field}.${payee.id}`] = payee[field];
            this.$refs[`${field}.${payee.id}`].close();
          });
        }, 200);
      },

      toggleCheckbox(payee, value) {
        this.$store.dispatch('updatePayee', payee)
        .then(response => {
          //
        });
      }
    }
  }
</script>
