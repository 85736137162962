<template src="./journal-overview.html"></template>
<style src="./journal-overview.css" scoped></style>

<script>
  import dayGridPlugin from '@fullcalendar/daygrid';
  import timeGridPlugin from '@fullcalendar/timegrid';
  import interactionPlugin from '@fullcalendar/interaction';
  import listPlugin from '@fullcalendar/list';
  import moment from 'moment';

  export default {
    name: 'journal-overview',
    data() {
      return {
        options: {
          customButtons: {
            newJournalEntry: {
              icon: 'plus-square',
              click: () => {
                return this.$router.push({
                  name: 'JournalView',
                  params: {
                    journalID: 'new'
                  }
                });
              }
            }
          },
          plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],
          header: {
            left: 'prev,today,next',
            center: 'title',
            right: 'newJournalEntry,dayGridMonth,listMonth'
          },
          editable: true,
          eventClick: (e) =>  {
            this.loadEntry(e.event);
          },
          datesRender: (e) => {
            this.handleMonthChanged(
              moment(e.view.activeStart).format('YYYY-MM-DD'),
              moment(e.view.activeEnd).format('YYYY-MM-DD')
            );
          }
        }
      }
    },

    created() {
      this.$store.commit('changeTitle', 'Journals | RSN');
    },

    computed: {
      journalEntries() {
        return this.$store.state.journalEntries;
      }
    },

    methods: {
      handleDayChanged(e) {
        console.log('day change:', e);
      },

      handleMonthChanged(start, end) {
        const params = {
          start_after: start,
          start_before: end
        };

        this.$store.dispatch('fetchJournals', params);
      },

      loadEntry(entry) {
        return this.$router.push({
          name: 'JournalView',
          params: {
            journalID: entry.id
          }
        });
      },

      createEntry() {
        return this.$router.push({
          name: 'JournalView',
          params: {
            journalID: 'new'
          }
        });
      }
    }
  }
</script>
