<template src="./transaction-view.html"></template>
<style src="./transaction-view.css" scoped></style>

<script>
  import axios from 'axios';
  import moment from 'moment';

  export default {
    name: 'transaction-view',
    data() {
      return {
        loading: false,
        editing: false,
        home: {icon: 'pi pi-home', command: () => this.filters = {}},
        transactions: [],
        filteredPayees: [],
        selectedTransactions: null,
        deleteTransactionsDialog: false,
        reconcileTransactionsDialog: false,
        filters: {},
        submitted: false,
        inplaceFields: {}
      }
    },

    created() {
      this.$store.commit('changeTitle', 'Transactions | RSN');
      this.fetchData(this.$route.query);
    },

    computed: {
      breadcrumbs() {
        const steps = [];

        if (this.$route.query.account) {
          const account = this.$store.state.accounts.find(o => o.id.toString() === this.$route.query.account);
          const accounts = this.$store.state.accounts.filter(o => o.id.toString() !== this.$route.query.account)
            .map(o => {
              return {
                label: o.name,
                command: () => this.filters = {
                  account: o.id
                }
              }
            });

          steps.push({
            label: 'Account',
            items: [
              {
                label: 'Budget',
                command: () => this.filters = {
                  budget_category: this.$store.state.budgetCategories[0].id
                }
              },
              {
                label: 'Transactions',
                command: () => this.filters = {}
              }
            ]
          });

          if (account) {
            steps.push({
              label: account.name,
              items: accounts
            });
          }
        }
        else if (this.$route.query.budget_category) {
          const budget = this.$store.state.budgetCategories.find(o => o.id.toString() === this.$route.query.budget_category);
          const budgets = this.$store.state.budgetCategories.filter(o => o.id.toString() !== this.$route.query.budget_category)
          .map(o => {
            return {
              label: o.name,
              command: () => this.filters = {
                budget_category: o.id
              }
            }
          });

          steps.push({
            label: 'Budget',
            items: [
              {
                label: 'Account',
                command: () => this.filters = {
                  account: this.$store.state.accounts[0].id
                }
              },
              {
                label: 'Transactions',
                command: () => this.filters = {}
              }
            ]
          });

          if (budget) {
            steps.push({
              label: budget.name,
              items: budgets
            });
          }
        }
        else {
          steps.push({
            label: 'Transactions',
            items: [
              {
                label: 'Account',
                command: () => this.filters = {
                  account: this.$store.state.accounts[0].id
                }
              },
              {
                label: 'Budget',
                command: () => this.filters = {
                  budget_category: this.$store.state.budgetCategories[0].id
                }
              }
            ]
          });
        }

        return steps;
      },

      availableBalance() {
        if (!this.transactions.length) {
          return;
        }

        const account = this.$store.state.accounts.find(o => o.id.toString() === this.$route.query.account);

        if (!account) {
          return;
        }

        let balance = parseFloat(account.balance);

        for (let transaction of this.transactions) {
          if (!transaction.cleared) {
            if (transaction.type === 'deposit') {
              balance -= parseFloat(transaction.amount);
            }
            else if (transaction.type === 'withdrawal') {
              balance += parseFloat(transaction.amount);
            }
          }
        }

        return balance;
      },

      accounts() {
        return this.$store.state.accounts;
      },

      budgetCategories() {
        return this.$store.state.budgetCategories;
      }
    },

    watch: {
      filters() {
        if (!this.filters) {
          this.$router.replace({
            path: this.$route.path
          });
          this.fetchData();
          return;
        }

        this.$router.replace({
          path: this.$route.path,
          query: this.filters
        });
        this.fetchData(this.filters);
      }
    },

    methods: {
      // API

      fetchData(params) {
        axios.get('/transaction/', {
          params: params
        })
        .then(response => {
          this.transactions = response.data.results.map(transaction => {
            transaction.amount = parseFloat(transaction.amount);
            return transaction;
          });
        })
        .catch(err => {
          console.error('error loading transactions:', err);
        });
      },

      reconcile() {
        const account = this.$store.state.accounts.find(o => o.id.toString() === this.$route.query.account);

        if (!account) {
          return;
        }

        axios.post(`account/${account.id}/reconcile/`)
        .then(response => {
          for (let transaction of this.transactions) {
            if (transaction.cleared) {
              transaction.reconciled = true;
            }
          }

          this.reconcileTransactionsDialog = false;
        })
        .catch(err => {
          console.error('error reconciling account', err);
        })
      },

      // Management

      openNew() {
        this.$store.commit('toggleTransactionDialog', true);
      },

      exportCSV() {
        this.$refs.dt.exportCSV();
      },

      confirmDeleteSelected() {
        this.deleteTransactionsDialog = true;
      },

      deleteSelectedTransactions() {
        this.transactions = this.transactions.filter(val => !this.selectedTransactions.includes(val));
        this.deleteTransactionsDialog = false;
        this.selectedTransactions = null;
        this.$toast.add({severity:'success', summary: 'Successful', detail: 'Transactions Deleted', life: 3000});
      },

      clearClass(transaction) {
        if (transaction.reconciled && !this.editing) {
          return '';
        }

        let classes = 'pi ';

        if (transaction.cleared) {
          classes += 'pi-check-circle';
        }
        else {
          classes += 'pi-circle';
        }

        return classes;
      },

      toggleClear(transaction) {
        transaction.reconciled = false;

        const patch = {
          cleared: !transaction.cleared
        };

        axios.patch(`/transaction/${transaction.id}/`, patch)
        .then(response => {
          transaction.cleared = !transaction.cleared;
        })
        .catch(err => {
          console.error('transaction clear error:', err);
        });
      },

      // Cell editing

      startEdit(field, id, data) {
        this.inplaceFields[`${field}.${id}`] = data;

        setTimeout(() => {
          if (typeof data === 'string') {
            this.$refs[`${field}.${id}`].$children[0].$el.focus();
          }
          else {
            this.$refs[`${field}.${id}`].$children[0].$children[0].$el.focus();
          }
        });
      },

      cancelEdit(field, transaction) {
        transaction[field] = this.inplaceFields[`${field}.${transaction.id}`];
        delete this.inplaceFields[`${field}.${transaction.id}`];
      },

      saveEdit(field, transaction) {
        if (transaction[field] === this.inplaceFields[`${field}.${transaction.id}`]) {
          return this.$refs[`${field}.${transaction.id}`].close();
        }

        setTimeout(() => {
          if (!(`${field}.${transaction.id}` in this.inplaceFields)) {
            return;
          }

          this.$store.dispatch('updateTransaction', transaction)
          .then(response => {
            this.inplaceFields[`${field}.${transaction.id}`] = transaction[field];
            this.$refs[`${field}.${transaction.id}`].close();
          });
        }, 200);
      },

      startEditTime(field, id, data) {
        this.inplaceFields = new Date(data);

        setTimeout(() => {
          this.$refs[`${field}.${id}`].$children[0].$children[0].$el.focus();
        });
      },

      cancelEditTime(field, transaction) {
        if (this.submitted) {
          return;
        }

        this.inplaceFields = {};
        this.$refs[`${field}.${transaction.id}`].close();
      },

      saveEditTime(field, transaction) {
        this.submitted = true;

        transaction[field] = moment(this.inplaceFields).format();

        setTimeout(() => {
          this.$store.dispatch('updateTransaction', transaction)
          .then(response => {
            this.inplaceFields = {};
            this.$refs[`${field}.${transaction.id}`].close();
            this.submitted = false;
          });
        }, 200);
      },

      startSelection(field, transaction) {
        this.inplaceFields = {
          name: transaction[field]
        };

        setTimeout(() => {
          this.$refs[`${field}.dropdown.${transaction.id}`].show();
        });
      },

      cancelSelection(field, transaction) {
        this.inplaceFields = {};

        if (!this.submitted) {
          this.$refs[`${field}.${transaction.id}`].close();
        }
      },

      saveSelection(field, transaction, selection) {
        if (transaction[field].name === selection.name) {
          return;
        }

        transaction[field] = selection;

        this.$store.dispatch('updateTransaction', transaction)
        .then(response => {
          this.submitted = false;
          this.$refs[`${field}.${transaction.id}`].close();
        })
        .catch(err => {
          this.submitted = false;
        });
      },

      startAutocomplete(field, transaction) {
        this.inplaceFields = transaction[field];

        setTimeout(() => {
          this.$refs[`${field}.autocomplete.${transaction.id}`].$el.firstChild.focus();
        });
      },

      cancelAutocomplete(field, transaction) {
        this.inplaceFields = {};

        if (!this.submitted) {
          this.$refs[`${field}.${transaction.id}`].close();
        }

        this.submitted = false;
      },

      saveAutocomplete(field, transaction, selection) {
        if (this.submitted) {
          return;
        }
        this.submitted = true;

        const originalValue = transaction[field];
        let payee = null;

        if (typeof this.inplaceFields === 'string') {
          payee = this.filteredPayees.find(o => {
            return o.name.toLowerCase() === this.inplaceFields.toLowerCase();
          });

          if (!payee) {
            // TODO: Implement payee creation
            //payee = {
            //  name: this.inplaceFields
            //};
          }
        }
        else if (selection.value && selection.value.name) {
          payee = selection.value;
        } else if (this.inplaceFields.name) {
          payee = this.inplaceFields;
        }

        if (!payee) {
          return;
        }

        transaction[field] = payee;

        this.$store.dispatch('updateTransaction', transaction)
        .then(response => {
          this.submitted = false;
          this.$refs[`${field}.${transaction.id}`].close();
        })
        .catch(err => {
          this.submitted = false;
          transaction[field] = originalValue;
        });
      },

      searchPayees(event) {
        const query = event.query;

        this.$store.dispatch('fetchPayee', {
          "name__icontains": query,
          "page_size": 10
        })
        .then(response => {
          this.filteredPayees = response.results;
        });
      }
    },

    filters: {
      amount(transaction) {
        let v = transaction.amount;
        let prefix = '';

        if (typeof value === 'string') {
          v = parseFloat(transaction.amount);
        }

        if (transaction.type === 'withdrawal') {
          prefix = '-';
        }

        return prefix + formatter.format(v);
      }
    }
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });
</script>