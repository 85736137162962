<template src="./login.html"></template>
<style src="./login.css" scoped></style>

<script>
  export default {
    name: 'login',
    data() {
      return {
        username: '',
        password: ''
      }
    },

    methods: {
      login() {
        return this.$store.dispatch('login', {
          username: this.username,
          password: this.password
        });
      }
    }
  }
</script>
