<template src="./budget-view.html"></template>
<style src="./budget-view.css" scoped></style>

<script>
  import axios from 'axios';

  export default {
    name: 'budget-view',
    data() {
      return {
        loading: false,
        editing: false,
        monthSelection: '',
        selectedDate: new Date(),
        budgets: [],
        budgetGroups: [],
        activeTab: 0,
        bcgDialog: false,
        budget: {category: {}},
        budgetCategoryGroup: {},
        selectedGroups: null,
        deleteGroupsDialog: false,
        submitted: false,
        editingInplace: false,
        inplaceFields: {}
      }
    },

    created() {
      const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ];
      const now = new Date();
      this.monthSelection = `${monthNames[now.getMonth()]} ${now.getFullYear()}`;

      this.$store.commit('changeTitle', 'Budget | RSN');
      Promise.all([
        this.fetchBudgetGroupData(),
        this.fetchBudgetData()
      ])
      .then(([groups, budgets, ...values]) => {
        this.groupBudgets(groups, budgets);
      })
      .catch(err => {
        //
      });
    },

    watch: {
      '$store.state.latestTransaction'() {
        const transaction = this.$store.state.latestTransaction;

        if (!transaction || !transaction.budget_category) {
          return;
        }

        const date = new Date(transaction.time);
        if (date.getFullYear() !== this.selectedDate.getFullYear()
            || date.getMonth() !== this.selectedDate.getMonth()
        ) {
          return;
        }

        const group     = this.budgetGroups.find(o => o.id === transaction.budget_category.group.id);
        const budget    = group.budgets.find(o => o.category.id === transaction.budget_category.id);
        const allocated = parseFloat(budget.allocated);
        const amount    = parseFloat(transaction.amount);

        budget.allocated = allocated + amount;
      }
    },

    methods: {
      fetchBudgetGroupData() {
        return axios.get('/budgetcategorygroup/')
        .then(response => {
          return response.data.results;
        })
        .catch(err => {
          console.error('error loading budget groups:', err);
        });
      },

      fetchBudgetData(year, month) {
        const now = new Date();

        if (!year) {
          year = now.getFullYear();
        }
        if (!month) {
          month = now.getMonth() + 1;
        }

        return axios.get('/budget/', {
          params: {
            year: year,
            month: month
          }
        })
        .then(response => {
          this.budgets = response.data.results.map(budget => {
            budget.amount = parseFloat(budget.amount);
            return budget;
          });
          return response.data.results;
        })
        .catch(err => {
          console.error('error loading budgets:', err);
        });
      },

      openNew() {
        const today = new Date();

        this.budgetCategoryGroup = {};
        this.budget = {
          category: {},
          year: today.getFullYear(),
          month: today.getMonth() + 1,
          amount: 0.00
        };
        this.activeTab = 0;
        this.submitted = false;
        this.bcgDialog = true;
      },

      hideDialog() {
        this.bcgDialog = false;
        this.submitted = false;
      },

      createGroup() {
        if (this.activeTab === 0) {
          this.createBudget();
          return;
        }

        if (!this.budgetCategoryGroup.name.trim()) {
          this.submitted = true;
          return;
        }

        this.$store.dispatch('createBudgetCategoryGroup', this.budgetCategoryGroup)
        .then(group => {
          this.budgetGroups.push(group);
          this.$toast.add({severity:'success', summary: 'Successful', detail: 'Budget Category Group Created', life: 2000});

          this.submitted = true;
          this.bcgDialog = false;
          this.budgetCategoryGroup = {};
        })
        .catch(err => {
          this.$toast.add({severity: 'error', summary: 'Failure', detail: 'Budget Category Group Creation Error', life: 2000});
        });
      },

      createBudget() {
        if (!this.budget.category.name.trim()) {
          this.submitted = true;
          return;
        }

        this.$store.dispatch('createBudget', this.budget)
        .then(budget => {
          this.budgets.push(budget);
          this.$toast.add({severity:'success', summary: 'Successful', detail: 'Budget Created', life: 2000});


          this.submitted = true;
          this.bcgDialog = false;
          this.budget = {category: {}};
        })
        .catch(err => {
          this.$toast.add({severity: 'error', summary: 'Failure', detail: 'Budget Creation Error', life: 2000});
        });
      },

      exportCSV() {
        // TODO: Implement new functionality or remove CSV button
        //this.$refs.dt.exportCSV();
      },

      confirmDeleteSelected() {
        this.deleteGroupsDialog = true;
      },

      deleteSelectedGroups() {
        this.budgetGroups = this.budgetGroups.filter(val => !this.selectedGroups.includes(val));
        this.deleteGroupsDialog = false;
        this.selectedGroups = null;
        this.$toast.add({severity:'success', summary: 'Successful', detail: 'Products Deleted', life: 3000});
      },

      selectDate(date) {
        this.loading = true;
        this.selectedDate = date;
        this.fetchBudgetData(date.getFullYear(), date.getMonth() + 1)
        .then(budgets => {
          this.groupBudgets(this.budgetGroups, budgets);
          this.loading = false;
        });
      },

      loadTransactions(budget) {
        if (this.editing) {
          return;
        }

        this.$router.push({
          name: 'TransactionView',
          query: {
            budget_category: budget.category.id
          }
        });
      },

      // Drag and Drop

      dragstartHandler(ev) {
        const type = ev.target.className;
        const {groupIndex, budgetIndex} = this.getDropID(type, ev.target);
        const collections = {
          budget: 'budgets',
          group: 'budgetCategories'
        };

        ev.dataTransfer.setData('finance/type', type);
        ev.dataTransfer.setData('finance/collection', collections[type]);
        ev.dataTransfer.setData('group/index', groupIndex);
        ev.dataTransfer.setData('budget/index', budgetIndex);
        ev.dataTransfer.effectAllowed = 'move';
      },

      dragoverHandler(ev) {
        ev.preventDefault();
      },

      dropHandler(ev) {
        ev.preventDefault();
        const collectionStr   = ev.dataTransfer.getData('finance/collection');
        const type            = ev.dataTransfer.getData('finance/type');
        const dragGroupIndex  = parseInt(ev.dataTransfer.getData('group/index'));
        const dragBudgetIndex = parseInt(ev.dataTransfer.getData('budget/index'));
        const {groupIndex, budgetIndex} = this.getDropID(type, ev.target);

        if (type === 'group') {
          const arr = this.moveArrayItemToNewIndex(Array.from(this.budgetGroups), dragGroupIndex, groupIndex);

          return this.updateOrder(this.budgetGroups, arr, dragGroupIndex, groupIndex)
          .then(groups => {
            this.groupBudgets(groups, this.budgets);
          });
        }

        const updates   = [];
        const dragGroup = this.getRecord('group', dragGroupIndex, 'order');
        const dropGroup = this.getRecord('group', groupIndex, 'order');
        const budget    = this.getRecord('budget', dragGroup.budgets[dragBudgetIndex].id);
        budget.category.group = dropGroup;
        budget.category.order = (dragGroup.id === dropGroup.id) ? budgetIndex : budgetIndex + 1;

        if (budgetIndex < 0) {
          budget.category.order = 0;
        }

        for (const b of this.budgets) {
          if (b.category.group.id !== dragGroup.id
          && b.category.group.id !== dropGroup.id) {
            continue;
          }

          if (b.id === budget.id) {
            updates.push(this.$store.dispatch('updateBudgetCategory', b.category));
            continue;
          }

          const groupID = b.category.group.id;
          const order   = b.category.order;

          if (dragGroup.id === dropGroup.id) {
            const increment = (dragBudgetIndex > budgetIndex) ? 1 : -1;
            const start     = (dragBudgetIndex > budgetIndex) ? budgetIndex : dragBudgetIndex;
            const end       = (dragBudgetIndex > budgetIndex) ? dragBudgetIndex : budgetIndex;

            if (order < start) {
              continue;
            }

            if (order <= end) {
              b.category.order += increment;
              updates.push(this.$store.dispatch('updateBudgetCategory', b.category));
            }
          }
          else if (groupID === dragGroup.id) {
            if (order > dragBudgetIndex) {
              b.category.order--;
              updates.push(this.$store.dispatch('updateBudgetCategory', b.category));
            }
          }
          else if (groupID === dropGroup.id) {
            if (order > budgetIndex) {
              b.category.order++;
              updates.push(this.$store.dispatch('updateBudgetCategory', b.category));
            }
          }
        }

        Promise.all(updates)
        .then(response => {
          this.groupBudgets(this.budgetGroups, this.budgets);
        });
      },

      groupBudgets(groups, budgets) {
        for (const group of groups) {
          group.budgets = [];
        }

        for (const budget of budgets) {
          const group = groups.find(g => g.id === budget.category.group.id);
          group.budgets.push(budget);
        }

        for (const group of groups) {
          group.budgets.sort((a, b) => a.category.order - b.category.order);
        }

        this.budgetGroups = groups;
      },

      updateOrder(collection, updatedCollection, dragIndex, dropIndex) {
        const start = dragIndex > dropIndex ? dropIndex : dragIndex;
        const end = dragIndex > dropIndex ? dragIndex : dropIndex;

        const update = [];

        for (let x = start; x <= end; x++) {
          updatedCollection[x].order = x;
          update.push(
              this.$store.dispatch('updateBudgetCategoryGroup', updatedCollection[x])
          );
        }

        return Promise.all(update)
        .then(response => {
          return updatedCollection;
        })
        .catch(err => {
          this.$toast.add({severity: 'error', summary: 'Failure', detail: 'Budget Category Group Error', life: 2000});
          return collection;
        });
      },

      moveArrayItemToNewIndex(arr, oldIndex, newIndex) {
        if (newIndex >= arr.length) {
          let k = newIndex - arr.length + 1;
          while (k--) {
            arr.push(undefined);
          }
        }
        arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
        return arr;
      },

      // Cell editing

      startEdit(type, field, id, data) {
        this.editingInplace = true;
        this.inplaceFields[`${type}.${field}.${id}`] = data;

        setTimeout(() => {
          if (typeof data === 'string') {
            this.$refs[`${type}.${field}.input.${id}`][0].$el.focus();
          }
          else {
            this.$refs[`${type}.${field}.input.${id}`][0].$children[0].$el.focus();
          }
        });
      },

      cancelEdit(type, field, id) {
        this.editingInplace = false;
        const record = this.getRecord(type, id);
        record[field] = this.inplaceFields[`${type}.${field}.${id}`];
        delete this.inplaceFields[`${type}.${field}.${id}`];
      },

      saveEdit(type, field, id) {
        const dispatchNames = {
          group: 'updateBudgetCategoryGroup',
          budget: 'updateBudget',
          category: 'updateBudgetCategory'
        };
        const dispatch = dispatchNames[type];
        const record = this.getRecord(type, id);

        if (record[field] === this.inplaceFields[`${type}.${field}.${id}`]) {
          return this.$refs[`${type}.${field}.${id}`][0].close();
        }

        setTimeout(() => {
          if (!(`${type}.${field}.${id}` in this.inplaceFields)) {
            return;
          }

          this.$store.dispatch(dispatch, record)
          .then(response => {
            this.inplaceFields[`${type}.${field}.${id}`] = record[field];
            this.$refs[`${type}.${field}.${id}`][0].close();
          });
        }, 200);
      },

      // Filter, compute, etc.

      groupTotalBudgeted(group) {
        let total = 0.0;

        for (const budget of group.budgets) {
          total += parseFloat(budget.amount);
        }

        return total;
      },

      groupTotalAvailable(group) {
        let total = 0.0;

        for (const budget of group.budgets) {
          const amount = parseFloat(budget.amount);
          const allocated = parseFloat(budget.allocated);

          total += amount - allocated;
        }

        return total;
      },

      budgetAvailable(budget) {
        const amount = parseFloat(budget.amount);
        const allocated = parseFloat(budget.allocated);

        return amount - allocated;
      },

      getDropID(type, el) {
        if (!el) return;

        if (el.id && (el.className === 'group' || el.className === 'budget')) {
          let [type, groupIndex, budgetIndex] = el.id.split('-');
          if (typeof budgetIndex === 'undefined') {
            budgetIndex = -1;
          }
          return {
            groupIndex: parseInt(groupIndex),
            budgetIndex: parseInt(budgetIndex)
          };
        }
        else {
          return this.getDropID(type, el.parentElement);
        }
      },

      getRecord(type, id, field='id') {
        const collection = (type === 'group') ? this.budgetGroups : this.budgets;
        if (type === 'category') {
          const budget = collection.find(o => o.category[field] === id);
          return budget.category;
        }
        return collection.find(o => o[field] === id);
      },
    }
  }
</script>
