<template src="./journal-view.html"></template>
<style src="./journal-view.css" scoped></style>

<script>
  import axios from 'axios';

  export default {
    name: 'journal-view',
    data() {
      return {
        newJournal: false,
        start: {},
        end: {},
        displayDates: false,
        editing: false,
        journalEdits: {}
      };
    },

    created() {
      if (this.$route.params.journalID === 'new') {

        this.$store.commit('changeTitle', 'New Journal | RSN');
        const now = new Date();
        const newJournal = {
          subject: '',
          body: '',
          start: now,
          end: now
        };

        this.$store.commit('loadJournalEntry', newJournal);

        this.start = now;
        this.end = now;

        this.journalEdits = newJournal;
        this.newJournal = true;
        this.editing = true;
        this.displayDates = true;
        return;
      }

      this.$store.commit('changeTitle', 'Journal | RSN');

      axios.get('/journal/' + this.$route.params.journalID + '/')
      .then(response => {
        const entry = response.data;
        this.$store.commit('loadJournalEntry', entry);

        this.start = new Date(entry.start);
        this.end = new Date(entry.end);

        this.displayDates = true;
      })
      .catch(err => {
        console.error('error loading journal entry:', err);
      });
    },

    computed: {
      journalEntry() {
        return this.$store.state.journalEntry;
      },

      subjectClass() {
        return this.editing ? "p-input-icon-left p-input-icon-right" :
          "p-input-icon-right";
      }
    },

    methods: {
      editJournal() {
        this.journalEdits = {};
        Object.assign(this.journalEdits, this.$store.state.journalEntry);

        this.editing = true;
      },

      cancelEdits() {
        const entry = this.$store.state.journalEntry;

        this.start = new Date(entry.start);
        this.end = new Date(entry.end);

        this.editing = false;
        this.displayDates = false;

        // Just toggling the boolean back and forth doesn't trigger the date-time-picker to be destroyed
        // This timeout is the best method I could find to get the child components to use the reset date values
        setTimeout(() => {
          this.displayDates = true;
        }, 1);
      },

      saveEdits() {
        this.journalEdits.start = this.start;
        this.journalEdits.end = this.end;

        if (!this.newJournal) {
          this.$store.dispatch('updateJournal', this.journalEdits);
          this.editing = false;

          return;
        }

        this.$store.dispatch('createJournal', this.journalEdits)
        .then(() => {
          this.$router.push({
            'name': 'JournalOverview'
          });
        })
        .catch(err => {
          //TODO: display errors
        });
      },

      setDate(ref, date) {
        this.journalEdits[ref] = date;

        //TODO: Make sure the end date is *after* the start date.
      }
    }
  };
</script>
