<template src="./app.html"></template>
<style src="./app.css" scoped></style>

<script>
  import AppTopBar from './AppTopbar.vue';
  import AppProfile from './AppProfile.vue';
  import AppMenu from './AppMenu.vue';
  import AppConfig from './AppConfig.vue';
  import AppFooter from './AppFooter.vue';

  export default {
    name: 'app',

    data() {
      return {
        layoutMode: 'static',
        layoutColorMode: 'dark',
        staticMenuInactive: false,
        overlayMenuActive: false,
        mobileMenuActive: false,
        openConfigurator: false,
        transactionTypes: [
          {name: 'withdrawal'},
          {name: 'deposit'},
          {name: 'transfer'}
        ],
        transaction: {},
        filteredPayees: [],
        submitted: false,
        menu: [
          {label: 'Dashboard', icon: 'pi pi-home', to: '/'},
          {
            label: 'Journal',
            icon: 'pi pi-book',
            items: [
              {label: 'Calendar', icon: 'pi pi-calendar', to: '/journal'},
              {label: 'List', icon: 'pi pi-list', to: '/journal'},
              {label: 'New', icon: 'pi pi-calendar-plus', to: '/journal/new'},
            ]
          },
          {
            label: 'Finances',
            icon: 'pi pi-dollar',
            items: [
              {label: 'Budget', icon: 'pi pi-money-bill', to: '/budget'},
              {label: 'Accounts', icon: 'pi pi-home', to: '/accounts'},
              {label: 'Reports', icon: 'pi pi-chart-bar', to: '/reports'},
              {label: 'Payees', icon: 'pi pi-users', to: '/payees'},
              {label: 'Transactions', icon: 'pi pi-dollar', to: '/transactions'},
              {label: 'Transaction', icon: 'pi pi-plus-circle', command: () => {this.openTransactionDialog()}},
            ]
          }
        ],
        sampleMenu : [
          {label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/'},
          {
            label: 'UI KIT', icon: 'pi pi-fw pi-sitemap',
            items: [
              {label: 'Form Layout', icon: 'pi pi-fw pi-id-card', to: '/formlayout'},
              {label: 'Input', icon: 'pi pi-fw pi-check-square', to: '/input'},
              {label: 'Button', icon: 'pi pi-fw pi-mobile', to: '/button'},
              {label: 'Table', icon: 'pi pi-fw pi-table', to: '/table'},
              {label: 'List', icon: 'pi pi-fw pi-list', to: '/list'},
              {label: 'Tree', icon: 'pi pi-fw pi-share-alt', to: '/tree'},
              {label: 'Panel', icon: 'pi pi-fw pi-tablet', to: '/panel'},
              {label: 'Overlay', icon: 'pi pi-fw pi-clone', to: '/overlay'},
              {label: 'Menu', icon: 'pi pi-fw pi-bars', to: '/menu'},
              {label: 'Message', icon: 'pi pi-fw pi-comment', to: '/messages'},
              {label: 'File', icon: 'pi pi-fw pi-file', to: '/file'},
              {label: 'Chart', icon: 'pi pi-fw pi-chart-bar', to: '/chart'},
              {label: 'Misc', icon: 'pi pi-fw pi-circle-off', to: '/misc'},
            ]
          },
          {
            label: "Utilities", icon:'pi pi-fw pi-globe',
            items: [
              {label: 'Display', icon:'pi pi-fw pi-desktop', to:'/display'},
              {label: 'Elevation', icon:'pi pi-fw pi-external-link', to:'/elevation'},
              {label: 'Flexbox', icon:'pi pi-fw pi-directions', to:'/flexbox'},
              {label: 'Icons', icon:'pi pi-fw pi-search', to:'/icons'},
              {label: 'Grid System', icon:'pi pi-fw pi-th-large', to:'/grid'},
              {label: 'Spacing', icon:'pi pi-fw pi-arrow-right', to:'/spacing'},
              {label: 'Typography', icon:'pi pi-fw pi-align-center', to:'/typography'},
              {label: 'Text', icon:'pi pi-fw pi-pencil', to:'/text'},
            ]
          },
          {
            label: 'Pages', icon: 'pi pi-fw pi-clone',
            items: [
              {label: 'Crud', icon: 'pi pi-fw pi-user-edit', to: '/crud'},
              {label: 'Calendar', icon: 'pi pi-fw pi-calendar-plus', to: '/calendar'},
              {label: 'Empty Page', icon: 'pi pi-fw pi-circle-off', to: '/empty'}
            ]
          },
          {
            label: 'Menu Hierarchy', icon: 'pi pi-fw pi-search',
            items: [
              {
                label: 'Submenu 1', icon: 'pi pi-fw pi-bookmark',
                items: [
                  {
                    label: 'Submenu 1.1', icon: 'pi pi-fw pi-bookmark',
                    items: [
                      {label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-bookmark'},
                      {label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-bookmark'},
                      {label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-bookmark'},
                    ]
                  },
                  {
                    label: 'Submenu 1.2', icon: 'pi pi-fw pi-bookmark',
                    items: [
                      {label: 'Submenu 1.2.1', icon: 'pi pi-fw pi-bookmark'},
                      {label: 'Submenu 1.2.2', icon: 'pi pi-fw pi-bookmark'}
                    ]
                  },
                ]
              },
              {
                label: 'Submenu 2', icon: 'pi pi-fw pi-bookmark',
                items: [
                  {
                    label: 'Submenu 2.1', icon: 'pi pi-fw pi-bookmark',
                    items: [
                      {label: 'Submenu 2.1.1', icon: 'pi pi-fw pi-bookmark'},
                      {label: 'Submenu 2.1.2', icon: 'pi pi-fw pi-bookmark'},
                      {label: 'Submenu 2.1.3', icon: 'pi pi-fw pi-bookmark'},
                    ]
                  },
                  {
                    label: 'Submenu 2.2', icon: 'pi pi-fw pi-bookmark',
                    items: [
                      {label: 'Submenu 2.2.1', icon: 'pi pi-fw pi-bookmark'},
                      {label: 'Submenu 2.2.2', icon: 'pi pi-fw pi-bookmark'}
                    ]
                  }
                ]
              }
            ]
          },
          {label: 'Documentation', icon: 'pi pi-fw pi-question', command: () => {window.location = "#/documentation"}},
          {label: 'View Source', icon: 'pi pi-fw pi-search', command: () => {window.location = "https://github.com/primefaces/sigma"}}
        ]
      }
    },

    created() {
      const savedUser = JSON.parse(localStorage.getItem('user')) || {};
      const csrf = localStorage.getItem('csrf');
      this.$store.commit('loginUser', {user: savedUser, csrf: csrf});
      Promise.all([
        this.$store.dispatch('fetchAccounts'),
        this.$store.dispatch('fetchBudgetCategories')
      ]);
    },

    watch: {
      $route() {
        this.menuActive = false;
        this.$toast.removeAllGroups();
      },

      transactionDialog() {
        this.startNewTransaction();
      }
    },

    computed: {
      authenticated() {
        return this.$store.state.authenticated;
      },

      name() {
        return this.$store.state.name;
      },

      accounts() {
        return this.$store.state.accounts;
      },

      budgetCategories() {
        return this.$store.state.budgetCategories;
      },

      transactionDialog: {
        get: function () {
          return this.$store.state.transactionDialog;
        },

        set: function (val) {
          this.$store.state.transactionDialog = val;
        }
      },

      containerClass() {
        if (!this.$store.state.authenticated) {
          return ['layout-wrapper', {
            'layout-overlay': true,
            'layout-static': false,
            'layout-static-sidebar-inactive': false,
            'layout-overlay-sidebar-active': false,
            'layout-mobile-sidebar-active': false,
            'p-input-filled': this.$store.state.inputStyle === 'filled',
            'p-ripple-disabled': this.$store.state.primevue.ripple === false
          }];
        }

        return ['layout-wrapper', {
          'layout-overlay': this.layoutMode === 'overlay',
          'layout-static': this.layoutMode === 'static',
          'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
          'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
          'layout-mobile-sidebar-active': this.mobileMenuActive,
          'p-input-filled': this.$store.state.inputStyle === 'filled',
          'p-ripple-disabled': this.$store.state.primevue.ripple === false
        }];
      },

      sidebarClass() {
        return ['layout-sidebar', {
          'layout-sidebar-dark': this.layoutColorMode === 'dark',
          'layout-sidebar-light': this.layoutColorMode === 'light'
        }];
      },

      logo() {
        return 'images/logo.png';
      },

      isTransfer() {
        return this.transaction.type && this.transaction.type.name === 'transfer';
      }
    },

    methods: {
      login(email, password) {
        return this.$store.dispatch('login', {
          email: email,
          password: password
        });
      },

      logout() {
        this.$store.dispatch('logout');
      },

      navigate(name) {
        return this.$router.push({
          name: name
        });
      },

      onWrapperClick() {
        if (!this.menuClick) {
          this.overlayMenuActive = false;
          this.mobileMenuActive = false;
        }
        this.menuClick = false;
      },

      onConfigurationToggle() {
        this.openConfigurator = !this.openConfigurator;
      },

      onMenuToggle(event) {
        this.menuClick = true;
        if (this.isDesktop()) {
          if (this.layoutMode === 'overlay') {
            if(this.mobileMenuActive === true) {
              this.overlayMenuActive = true;
            }
            this.overlayMenuActive = !this.overlayMenuActive;
            this.mobileMenuActive = false;
          }
          else if (this.layoutMode === 'static') {
            this.staticMenuInactive = !this.staticMenuInactive;
          }
        }
        else {
          this.mobileMenuActive = !this.mobileMenuActive;
        }
        event.preventDefault();
      },

      onSidebarClick() {
        this.menuClick = true;
      },

      onMenuItemClick(event) {
        if (event.item && !event.item.items) {
          this.overlayMenuActive = false;
          this.mobileMenuActive = false;
        }
      },

      onLayoutChange(layoutMode) {
        this.layoutMode = layoutMode;
      },

      onLayoutColorChange(layoutColorMode) {
        this.layoutColorMode = layoutColorMode;
      },

      addClass(element, className) {
        if (element.classList)
          element.classList.add(className);
        else
          element.className += ' ' + className;
      },

      removeClass(element, className) {
        if (element.classList)
          element.classList.remove(className);
        else
          element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
      },

      isDesktop() {
        return window.innerWidth > 1024;
      },

      isSidebarVisible() {
        if (this.isDesktop()) {
          if (this.layoutMode === 'static')
            return !this.staticMenuInactive;
          else if (this.layoutMode === 'overlay')
            return this.overlayMenuActive;
          else
            return true;
        }
        else {
          return true;
        }
      },

      openTransactionDialog() {
        this.submitted = false;
        this.$store.commit('toggleTransactionDialog', true);
      },

      hideDialog() {
        this.$store.commit('toggleTransactionDialog', false);
        this.submitted = false;
      },

      startNewTransaction() {
        this.transaction = {
          account: this.accounts[0],
          budget_category: this.budgetCategories[0],
          type: this.transactionTypes[0],
          payee: null
        };
      },

      saveTransaction() {
         if (!this.transaction.amount || this.transaction.amount < 0) {
           this.submitted = true;
           return;
         }

        // TODO: Look into directly assigning the string from the dropdown, instead of hacking around the library quirks
        const newTransaction = Object.assign({}, this.transaction);
        if (newTransaction.type) {
          newTransaction.type = newTransaction.type.name;
        }

        if (newTransaction.type === 'transfer') {
          delete newTransaction.payee;
          delete newTransaction.budget_category;

          if (newTransaction.account.id === newTransaction.to.id) {
            this.submitted = true;
            return;
          }
        }
        else if (typeof this.transaction.payee === 'string') {
          newTransaction.payee = {
            "name": this.transaction.payee
          };
        }

        this.$store.dispatch('createTransaction', newTransaction)
        .then(transaction => {
          this.$toast.add({severity:'success', summary: 'Successful', detail: 'Transaction Created', life: 2000});

          this.$store.commit('toggleTransactionDialog', false);
          this.transaction = {};
        })
        .catch(err => {
          this.$toast.add({severity: 'error', summary: 'Failure', detail: 'Transaction Creation Error', life: 2000});
        });
      },

      searchPayees(event) {
        const query = event.query;

        this.$store.dispatch('fetchPayee', {
          "name__icontains": query
        })
        .then(response => {
          this.filteredPayees = response.results;
        });
      }
    },

    beforeUpdate() {
      if (this.mobileMenuActive)
        this.addClass(document.body, 'body-overflow-hidden');
      else
        this.removeClass(document.body, 'body-overflow-hidden');
    },

    components: {
      'AppTopBar': AppTopBar,
      'AppProfile': AppProfile,
      'AppMenu': AppMenu,
      'AppConfig': AppConfig,
      'AppFooter': AppFooter,
    }
  }
</script>
