<template>
  <div class="layout-profile">
    <div>
      <img src="assets/layout/images/profile.png" alt="" />
    </div>
    <button class="p-link layout-profile-link" @click="onClick">
      <span class="username">{{ name }}</span>
      <i class="pi pi-fw pi-cog"></i>
    </button>
    <transition name="layout-submenu-wrapper">
      <ul v-show="expanded">
        <li>
          <button class="p-link">
            <i class="pi pi-fw pi-user"></i>
            <span>Account</span>
          </button>
        </li>
        <li>
          <button class="p-link">
            <i class="pi pi-fw pi-inbox"></i>
            <span>Notifications</span>
            <span class="menuitem-badge">2</span>
          </button>
        </li>
        <li>
          <button class="p-link" @click="logout">
            <i class="pi pi-fw pi-power-off"></i>
            <span>Logout</span>
          </button>
        </li>
      </ul>
    </transition>

  </div>
</template>

<script>
export default {
  data() {
    return {
      expanded: false
    }
  },
  methods: {
    onClick(event){
      this.expanded = !this.expanded;
      event.preventDefault();
    },

    logout() {
      this.$store.dispatch('logout');
    }
  },
  computed: {
    name() {
      return `${this.$store.state.user.first_name} ${this.$store.state.user.last_name}`;
    }
  }
}
</script>

<style scoped>

</style>
