<template src="./account-view.html"></template>
<style src="./account-view.css" scoped></style>

<script>
  import axios from 'axios';

  export default {
    name: 'account-view',
    data() {
      return {
        loading: false,
        editing: false,
        inplaceFields: {},
        accounts: [],
        accountTypes: [
          {name: 'checking'},
          {name: 'savings'},
          {name: 'credit'}
        ],
        accountDialog: false,
        account: {},
        selectedAccounts: null,
        deleteAccountsDialog: false,
        filters: {},
        submitted: false
      }
    },

    created() {
      this.$store.commit('changeTitle', 'Accounts | RSN');
      this.fetchData();
    },

    methods: {
      fetchData() {
        axios.get('/account/')
        .then(response => {
          this.accounts = response.data.results.map(account => {
            account.balance = parseFloat(account.balance);
            return account;
          });
        })
        .catch(err => {
          console.error('error loading accounts:', err);
        });
      },

      loadTransactions(event) {
        if (this.editing) {
          return;
        }

        const account = event.data;

        this.$router.push({
          name: 'TransactionView',
          query: {
            account: account.id
          }
        });
      },

      openNew() {
        const today = new Date();

        this.account = {};
        this.submitted = false;
        this.accountDialog = true;
      },

      hideDialog() {
        this.accountDialog = false;
        this.submitted = false;
      },

      saveAccount() {
        if (!this.account.name.trim()) {
          this.submitted = true;
          return;
        }

        if (this.account.id) {
          // TODO: Update existing record
          this.$set(this.accounts, this.findIndexById(this.account.id), this.account);
          this.$toast.add({severity:'success', summary: 'Successful', detail: 'Product Updated', life: 3000});
          this.submitted = true;
          this.accountDialog = false;
          this.account = {};
          return;
        }

        // TODO: Look into directly assigning the string from the dropdown, instead of hacking around the library quirks
        const newAccount = Object.assign({}, this.account);
        if (newAccount.account_type) {
          newAccount.account_type = newAccount.account_type.name;
        }

        this.$store.dispatch('createAccount', newAccount)
        .then(account => {
          this.accounts.push(account);
          this.$toast.add({severity:'success', summary: 'Successful', detail: 'Account Created', life: 2000});

          this.submitted = true;
          this.accountDialog = false;
          this.account = {};
        })
        .catch(err => {
          this.$toast.add({severity: 'error', summary: 'Failure', detail: 'Account Creation Error', life: 2000});
        });
      },

      findIndexById(id) {
        let index = -1;
        for (let i = 0; i < this.accounts.length; i++) {
          if (this.accounts[i].id === id) {
            index = i;
            break;
          }
        }

        return index;
      },

      exportCSV() {
        this.$refs.dt.exportCSV();
      },

      confirmDeleteSelected() {
        this.deleteAccountsDialog = true;
      },

      deleteSelectedAccounts() {
        this.accounts = this.accounts.filter(val => !this.selectedAccounts.includes(val));
        this.deleteAccountsDialog = false;
        this.selectedAccounts = null;
        this.$toast.add({severity:'success', summary: 'Successful', detail: 'Products Deleted', life: 3000});
      },

      isPositiveInteger(val) {
        let str = String(val);
        str = str.trim();
        if (!str) {
          return false;
        }
        str = str.replace(/^0+/, "") || "0";
        var n = Math.floor(Number(str));
        return n !== Infinity && String(n) === str && n >= 0;
      },

      // Cell editing

      startEdit(field, id, data) {
        this.inplaceFields[`${field}.${id}`] = data;

        setTimeout(() => {
          if (typeof data === 'string') {
            this.$refs[`${field}.${id}`].$children[0].$el.focus();
          }
          else {
            this.$refs[`${field}.${id}`].$children[0].$children[0].$el.focus();
          }
        });
      },

      cancelEdit(field, account) {
        account[field] = this.inplaceFields[`${field}.${account.id}`];
        delete this.inplaceFields[`${field}.${account.id}`];
      },

      saveEdit(field, account) {
        if (account[field] === this.inplaceFields[`${field}.${account.id}`]) {
          return this.$refs[`${field}.${account.id}`].close();
        }

        setTimeout(() => {
          if (!(`${field}.${account.id}` in this.inplaceFields)) {
            return;
          }

          this.$store.dispatch('updateAccount', account)
          .then(response => {
            this.inplaceFields[`${field}.${account.id}`] = account[field];
            this.$refs[`${field}.${account.id}`].close();
          });
        }, 200);
      },

      startSelection(field, account) {
        setTimeout(() => {
          this.$refs[`${field}.dropdown.${account.id}`].show();
        });
      },

      cancelSelection(field, account) {
        if (!this.submitted) {
          this.$refs[`${field}.${account.id}`].close();
        }
      },

      saveSelection(field, account, selection) {
        if (account[field] === selection.name) {
          return;
        }

        account.account_type = selection.name;

        this.$store.dispatch('updateAccount', account)
        .then(response => {
          this.submitted = false;
          this.$refs[`${field}.${account.id}`].close();
        })
        .catch(err => {
          this.submitted = false;
          console.error('error updating account:', err);
        });
      }
    }
  }
</script>
