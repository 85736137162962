// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import Vuex from 'vuex';
import axios from 'axios';
import moment from 'moment';

import './plugins/components';
import './plugins/primevue';

Vue.prototype.$moment = moment;
axios.defaults.withCredentials = true;
axios.interceptors.response.use(response => response, (err) => {
  if (err.response && err.response.status === 403 && !csrfError(err)) {
    // TODO: Don't do this for csrf errors?
    return router.push({
      name: 'Login'
    });
  }

  return Promise.reject(err);
});

Vue.config.productionTip = false;
Vue.use(Vuex);

function csrfError(err) {
  return err.response
  && err.response.data
  && err.response.data.detail
  && err.response.data.detail.includes('CSRF');
}

const BASE_URL = (process.env.NODE_ENV === 'development') ? 'http://localhost:8000/api' : 'https://cube.ron-smith.net/api';
axios.defaults.baseURL = BASE_URL;

const store = new Vuex.Store({
  state: {
    csrf: null,
    authenticated: false,
    user: {},
    journalEntry: {},
    journalEntries: [],
    name: '',
    baseUrl: BASE_URL,
    pageTitle: 'RSN',
    errors: [],
    dashboard: {},
    transactionDialog: false,
    transactionSubmitted: false,
    transaction: {},
    accounts: [],
    budgetCategories: [],
    latestTransaction: null,

    inputStyle: 'outlined',
    primevue: {
      ripple: true
    },
    debug: false
  },

  mutations: {
    loginUser(state, {user, csrf}) {
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('name', user.username);

      state.csrf = csrf;
      state.user = user;
      state.authenticated = Object.keys(user).length !== 0;

      if (csrf) {
        localStorage.setItem('csrf', csrf);
        state.name = user.username;

        axios.defaults.headers.common = {
          'X-CSRFToken': csrf
        };
      }
    },

    logoutUser(state) {
      localStorage.removeItem('user');
      localStorage.removeItem('name');

      state.user = {};
      state.name = '';
      state.authenticated = false;
    },

    changeTitle(state, title) {
      state.pageTitle = title;
      document.title = title;
    },

    logError(state, error) {
      state.errors.push(error);
    },

    updateDashboard(state, data) {
      state.dashboard = data;
    },

    loadJournals(state, journals) {
      journals.forEach(entry => {
        entry.date = moment(entry.start).format('YYYY/MM/DD');
        entry.desc = entry.body.split('.')[0];
        entry.title = entry.subject;
      });

      state.journalEntries = journals;
    },

    loadJournalEntry(state, entry) {
      state.journalEntry = entry;
    },

    addJournalEntry(state, entry) {
      entry.date = moment(entry.start).format('YYYY/MM/DD');
      entry.desc = entry.body.split('.')[0];
      entry.title = entry.subject;

      state.journalEntries.push(entry);
    },

    toggleTransactionDialog(state, showDialog) {
      state.transactionDialog = showDialog;
    },

    loadAccounts(state, accounts) {
      state.accounts = accounts;
    },

    addAccount(state, account) {
      state.accounts.push(account);
    },

    loadBudgetCategories(state, categories) {
      state.budgetCategories = categories;
    },

    addBudgetCategory(state, category) {
      const existing = state.budgetCategories.find(c => c.id === category.id);

      if (!existing) {
        state.budgetCategories.push(category);
      }
    },

    setLatestTransaction(state, transaction) {
      state.latestTransaction = transaction;
    }
  },

  actions: {
    // Session management
    login(context, credentials) {
      axios.post('/auth/login/', credentials)
      .then(response => {
        context.commit('loginUser', response.data);
        router.push({
          name: 'Dashboard'
        });
      })
      .catch(err => {
        console.error('login error:', err);
      });
    },

    logout(context) {
      axios.get('/auth/logout/')
      .then(response => {
        context.commit('logoutUser');
        router.push({
          name: 'Login'
        });
      })
      .catch(err => {
        console.error('logout error:', err);
      });
    },

    // Dashboard
    fetchDashboard(context, params) {
      axios.get('/dashboard/')
      .then(response => {
        context.commit('updateDashboard', response.data);
      })
      .catch(err => {
        console.error('error loading dashboard:', err);
      });
    },

    // User management
    //TODO: user related API calls

    // Journal management
    fetchJournals(context, params) {
      axios.get('/journal/', {
        params: params
      })
      .then(response => {
        context.commit('loadJournals', response.data.results);
      })
      .catch(err => {
        console.error('error loading journals:', err);
      });
    },

    fetchJournalEntry(context, params) {
      axios.get(`/journal/${params.id}/`)
      .then(response => {
        context.commit('loadJournalEntry', response.data);
      })
      .catch(err => {
        console.error('error loading journal entry:', err);
      });
    },

    createJournal(context, journal) {
      if (journal.id) {
        return;
      }

      return axios.post('/journal/', journal)
      .then(response => {
        context.commit('addJournalEntry', response.data);
        return Promise.resolve(response.data);
      })
      .catch(err => {
        console.error('create journal error:', err);
        return Promise.reject(err);
      });
    },

    updateJournal(context, journal) {
      if (!journal.id) {
        return;
      }

      return axios.put('/journal/', journal)
      .then(response => {
        context.commit('loadJournalEntry', response.data);

        return Promise.resolve(response.data);
      })
      .catch(err => {
        console.error('update journal error:', err);
        return Promise.reject(err);
      });
    },

    // Finances
    fetchBudgetCategories(context, params) {
      return axios.get('/budgetcategory/', {
        params: params
      })
      .then(response => {
        context.commit('loadBudgetCategories', response.data.results);
      })
      .catch(err => {
        console.err('error loading budget categories:', err);
      });
    },

    createBudgetCategoryGroup(context, group) {
      if (group.id) {
        return;
      }

      return axios.post('/budgetcategorygroup/', group)
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(err => {
        console.error('create budget category group error:', err);
        return Promise.reject(err);
      });
    },

    createBudgetCategory(context, category) {
      if (category.id) {
        return;
      }

      return axios.post('/budgetcategory/', category)
      .then(response => {
        context.commit('addBudgetCategory', response.data);
        return Promise.resolve(response.data);
      })
      .catch(err => {
        console.error('create budget category error:', err);
        return Promise.reject(err);
      });
    },

    createBudget(context, budget) {
      if (budget.id) {
        return;
      }

      return axios.post('/budget/', budget)
      .then(response => {
        context.commit('addBudgetCategory', response.data.category);
        return Promise.resolve(response.data);
      })
      .catch(err => {
        console.error('create budget error:', err);
        return Promise.reject(err);
      })
    },

    updateBudgetCategoryGroup(context, group) {
      if (!group.id) {
        return;
      }

      return axios.put(`/budgetcategorygroup/${group.id}/`, group)
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(err => {
        console.error('update budget category group error:', err);
        return Promise.reject(err);
      });
    },

    updateBudget(context, budget) {
      if (!budget.id) {
        return;
      }

      return axios.put(`/budget/${budget.id}/`, budget)
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(err => {
        console.error('update budget error:', err);
        return Promise.reject(err);
      });
    },

    updateBudgetCategory(context, category) {
      if (!category.id) {
        return;
      }

      return axios.put(`/budgetcategory/${category.id}/`, category)
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(err => {
        console.error('update budget category error:', err);
        return Promise.reject(err);
      });
    },

    fetchAccounts(context, params) {
      return axios.get('/account/', {
        params: params
      })
      .then(response => {
        context.commit('loadAccounts', response.data.results);
      })
      .catch(err => {
        console.error('error loading accounts:', err);
      });
    },

    createAccount(context, acccount) {
      if (acccount.id) {
        return;
      }

      return axios.post('/account/', acccount)
      .then(response => {
        context.commit('addAccount', response.data);
        return Promise.resolve(response.data);
      })
      .catch(err => {
        console.error('create account error:', err);
        return Promise.reject(err);
      })
    },

    updateAccount(context, account) {
      if (!account.id) {
        return;
      }

      return axios.put(`/account/${account.id}/`, account)
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(err => {
        console.error('update account error:', err);
        return Promise.reject(err);
      });
    },

    fetchPayee(context, params) {
      return axios.get('/payee/', {
        params: params
      })
      .then(response => {
        return response.data;
      })
      .catch(err => {
        console.error('error loading payees:', err);
      });
    },

    createPayee(context, payee) {
      if (payee.id) {
        return;
      }

      return axios.post('/payee/', payee)
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(err => {
        console.error('create payee error:', err);
        return Promise.reject(err);
      })
    },

    updatePayee(context, payee) {
      if (!payee.id) {
        return;
      }

      return axios.put(`/payee/${payee.id}/`, payee)
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(err => {
        console.error('update payee error:', err);
        return Promise.reject(err);
      });
    },

    createTransaction(context, transaction) {
      if (transaction.id) {
        return;
      }

      return axios.post('/transaction/', transaction)
      .then(response => {
        const transaction = response.data;
        context.commit('setLatestTransaction', transaction);
        return Promise.resolve(transaction);
      })
      .catch(err => {
        console.error('create transaction error:', err);
        return Promise.reject(err);
      })
    },

    updateTransaction(context, transaction) {
      if (!transaction.id) {
        return;
      }

      return axios.put(`/transaction/${transaction.id}/`, transaction)
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(err => {
        console.error('update transaction error:', err);
        return Promise.reject(err);
      });
    },
  }
});

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

Vue.filter('money', (value) => {
  let v = value;

  if (typeof value === 'string') {
    v = parseFloat(value);
  }
  return formatter.format(v);
});

Vue.filter('date', (value) => {
  return moment(value).format('YYYY-MM-DD hh:mmA');
});

Vue.filter('simpledate', (value) => {
  return moment(value).format('YYYY-MM-DD');
});

Vue.filter('time', (value) => {
  return moment(value).format('hh:mmA');
});

Vue.filter('field', (obj, field) => {
  if (!obj) {
    return;
  }
  return obj[field];
});

/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: createEle => createEle(App)
}).$mount('#app');
