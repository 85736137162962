<template src="./breadcrumb-menu.html"></template>
<style src="./breadcrumb-menu.css" scoped></style>

<script>
  export default {
    name: 'breadcrumb-menu',
    props: {
      home: {
        type: Object,
        default(value) {
          return {icon: 'pi pi-home'};
        }
      },
      model: {
        type: Array,
        default(value) {
          return [];
        }
      },
      buttonClass: {
        type: String,
        default: 'p-button-text p-button-plain'
      }
    },
    data() {
      return {
        s: null
      }
    },
    methods: {
      toggle(event, label) {
        const l = 'menu' + label;
        this.$refs[l][0].toggle(event);
      },
    }
  }
</script>