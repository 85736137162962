import Vue from 'vue';
import Router from 'vue-router';
import Login from '@/views/User/Login/Login.vue';
import Dashboard from '@/views/Dashboard/Dashboard.vue';
import JournalOverview from '@/views/Journal/JournalOverview/JournalOverview.vue';
import JournalView from '@/views/Journal/JournalView/JournalView.vue';
import BudgetView from '@/views/Finance/BudgetView/BudgetView.vue';
import AccountView from '@/views/Finance/AccountView/AccountView.vue';
import ReportView from '@/views/Finance/ReportView/ReportView.vue';
import PayeeView from '@/views/Finance/PayeeView/PayeeView.vue';
import TransactionView from '@/views/Finance/TransactionView/TransactionView.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Index',
      component: Dashboard
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: Dashboard
    },
    {
      path: '/journal',
      name: 'JournalOverview',
      component: JournalOverview
    },
    {
      path: '/journal/:journalID',
      name: 'JournalView',
      component: JournalView
    },
    {
      path: '/budget',
      name: 'BudgetView',
      component: BudgetView
    },
    {
      path: '/accounts',
      name: 'AccountView',
      component: AccountView
    },
    {
      path: '/reports',
      name: 'ReportView',
      component: ReportView
    },
    {
      path: '/payees',
      name: 'PayeeView',
      component: PayeeView
    },
    {
      path: '/transactions',
      name: 'TransactionView',
      component: TransactionView
    }
  ]
});
